:root {
  --altVerticalSpace200: 200px;
  --altVerticalSpace120: 120px;
  --altVerticalSpace70: 70px;
  --altVerticalSpace56: 56px;
  --altVerticalSpace40: 40px;
  --headerHeight: 148px;
  --generalAngle: -21.3deg;
  --galleryImageHeight: 546px;
  --moodHeight: 548px;
  --generalUnitPadding: 32px;
  --buttonPrimaryBackground: #E6F3EB;
  --buttonPrimaryOverlay: #007D34;
  --buttonSecondaryOverlay: #B0DAC2;
}
:root .section--pictBlock,
:root .area--two .unitTwo,
:root .area--two .unitFive,
:root .navigationMobile {
  --buttonPrimaryBackground: #fff;
}
:root .layout6 .section--multimood,
:root .layout8 .section--multimood {
  --moodHeight: calc(100vh - var(--headerHeight));
}
@media (max-width: 1199px) {
  :root {
    --altVerticalSpace200: 120px;
    --moodHeight: 480px;
  }
  :root .layout6 .section--multimood,
  :root .layout8 .section--multimood {
    --moodHeight: 480px;
  }
}
@media (max-width: 767px) {
  :root {
    --altVerticalSpace70: 64px;
    --altVerticalSpace56: 40px;
    --altVerticalSpace40: 32px;
    --headerHeight: 72px;
    --galleryImageHeight: 328px;
    --generalUnitPadding: 24px;
    --moodHeight: 290px;
  }
  :root .layout6 .section--multimood,
  :root .layout8 .section--multimood {
    --moodHeight: 340px;
  }
}
:root {
  --deskWidth: 1344px;
  --deskSpace: 48px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
:root .section--pictBlock,
:root .area--two .unitFive {
  --deskWidth: 1120px;
}
:root .section--titles,
:root .section--one {
  --deskWidth: 888px;
}
:root .layout6 .section--multimood,
:root .layout8 .section--multimood {
  --deskWidth: 1344px;
}
@media (max-width: 1199px) {
  :root {
    --deskSpace: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 24px;
  }
}
:root {
  --spacePart: 8px;
}
@media (max-width: 1199px) {
  :root {
    --spacePart: 8px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 8px;
  }
}
:root {
  --spaceUnit: 52px;
}
@media (max-width: 1199px) {
  :root {
    --spaceUnit: 52px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 32px;
  }
}
:root {
  --spaceTotal: 60px;
}
@media (max-width: 1199px) {
  :root {
    --spaceTotal: 60px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 40px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1199px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.bodyNorm {
  font-size: 18px;
  line-height: 1.55555556;
  font-weight: 400;
}
@media (max-width: 767px) {
  .bodyNorm {
    font-size: 16px;
    line-height: 1.625;
  }
}
.bodySmall {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 400;
}
@media (max-width: 767px) {
  .bodySmall {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .button {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.button:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.button:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .button:hover {
    color: #007D34 !important;
  }
  .button:hover:before {
    width: 0;
  }
  .button:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .button {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.button--woArrow {
  padding-left: 24px;
  color: #007D34 !important;
}
.button--woArrow:before {
  right: initial;
  left: -10px;
  width: 0;
  background-color: var(--buttonSecondaryOverlay);
}
.button--woArrow:after {
  display: none;
}
@media (min-width: 768px) {
  .button--woArrow:hover:before {
    width: calc(100% + 20px);
  }
}
@font-face {
  font-family: "Flama";
  font-weight: 400;
  src: url("/extras/fonts/Flama-Book.woff2") format("woff2"), url("/extras/fonts/Flama-Book.woff") format("woff");
}
@font-face {
  font-family: "Flama";
  font-weight: 500;
  src: url("/extras/fonts/Flama-Medium.woff2") format("woff2"), url("/extras/fonts/Flama-Medium.woff") format("woff");
}
@font-face {
  font-family: "Flama";
  font-weight: 700;
  src: url("/extras/fonts/Flama-Bold.woff2") format("woff2"), url("/extras/fonts/Flama-Bold.woff") format("woff");
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
}
*::selection {
  background: #005997;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 700;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 18px;
  font-family: 'Flama', sans-serif;
  line-height: 1.55555556;
}
.unit caption {
  display: none;
}
.flag {
  background: #005997;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
  min-height: 0 !important;
}
#edit .area.area--empty {
  min-height: 200px !important;
}
#view .area:empty {
  display: none;
}
.area .unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.unit .part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.part__content {
  float: left;
  width: 100%;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.lazyimage {
  visibility: hidden;
}
.lazyimage.lazyloaded {
  visibility: initial;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #191F24;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
.section--multimood #expo {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + ((100vw - var(--deskWidth)) / 2));
  height: 100%;
}
@media (max-width: 1216px) {
  .section--multimood #expo {
    width: calc(100% + var(--deskSpace));
  }
}
.section--three #expo {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
#slides {
  float: left;
  width: 100%;
  height: 414px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1199px) {
  #slides {
    height: 320px !important;
  }
}
@media (max-width: 767px) {
  #slides {
    height: 154px !important;
  }
}
.section--multimood #slides {
  height: 100% !important;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
.layout8 .cb-slides .cb-image-container {
  position: relative;
}
.layout8 .cb-slides .cb-image-container:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 170px;
  background-image: linear-gradient(to bottom, transparent, #191F24);
  pointer-events: none;
  opacity: 0.7;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#slides img.lazyimage {
  visibility: initial;
  filter: blur(10px);
  transform: scale(1.05);
  transition: all 0.12s;
}
#slides img.lazyimage.lazyloaded {
  filter: blur(0);
  transform: scale(1);
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: var(--altVerticalSpace56);
}
.section--three #expo div.link {
  display: flex;
}
@media (max-width: 767px) {
  #expo div.link {
    margin-top: 16px;
  }
}
#expo a.link {
  float: left;
  flex-shrink: 0;
  order: 2;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 42px;
  height: 42px;
  border-radius: 10000px;
  background-color: #E6F3EB;
  position: relative;
  transition: all 0.24s;
}
#expo a.link:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #007D34;
  mask-size: 16px auto;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
}
@media (min-width: 768px) {
  #expo a.link:hover {
    background-color: #B0DAC2;
  }
}
#expo a.link.prev {
  margin-left: 33px;
}
#expo a.link.prev:after {
  mask-image: url(/images/arrow-left.svg);
}
#expo a.link.next {
  margin-left: 16px;
}
#expo a.link.next:after {
  mask-image: url(/images/arrow-right.svg);
}
table.link,
table.link tbody {
  float: left;
  width: 100%;
  display: block;
  order: 1;
}
table.link:has(.cb-index-some),
table.link tbody:has(.cb-index-some) {
  display: none !important;
}
.cb-index-all {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.cb-index-all td {
  float: left;
  display: block;
}
.cb-index-all a {
  display: block;
  width: 32px;
  height: 32px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  position: relative;
}
.cb-index-all a:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 10000px;
  background: #E6F3EB;
  transition: all 0.24s;
}
.cb-index-all td.this a:after {
  background-color: #007D34;
  transform: translate(-50%, -50%) scale(1.2);
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  display: none !important;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(96, 105, 110, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Flama', sans-serif;
  line-height: 1.42857143;
  color: #191F24;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #005997;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #005997;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
  line-height: 1.42857143;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
#disp .foot input:before,
#disp .foot a:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
#disp .foot input:after,
#disp .foot a:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  #disp .foot input:hover,
  #disp .foot a:hover {
    color: #007D34 !important;
  }
  #disp .foot input:hover:before,
  #disp .foot a:hover:before {
    width: 0;
  }
  #disp .foot input:hover:after,
  #disp .foot a:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input[type*="radio"],
#disp input[type*="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1199px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
.moodSearch {
  position: absolute;
  left: 50%;
  bottom: 56px;
  transform: translateX(-50%);
  z-index: 10;
  width: 862px;
  max-width: calc(100% - 300px);
}
@media (max-width: 1199px) {
  .moodSearch {
    max-width: var(--deskMaxWidth);
  }
}
@media (max-width: 767px) {
  .moodSearch {
    bottom: 32px;
  }
}
.moodSearch .search {
  float: left;
  width: 100%;
  position: relative;
}
.moodSearch .search__input {
  float: left;
  width: 100%;
  background-color: #fff;
  border-radius: 10000px;
  height: 64px;
  box-sizing: border-box;
  padding: 0 64px;
  box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.04), 0px 4px 6px -2px rgba(0, 0, 0, 0.01);
}
.moodSearch .search__input::placeholder {
  font-style: italic;
  color: #191F24;
}
@media (max-width: 767px) {
  .moodSearch .search__input {
    height: 56px;
    padding: 0 56px 0 32px;
  }
}
.moodSearch .search__submit {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 48px;
  height: 48px;
  border-radius: 10000px;
  background-color: #007D34;
  cursor: pointer;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-search-white.svg);
}
.moodSearch .search__submit:hover {
  background-color: #006128;
}
@media (max-width: 767px) {
  .moodSearch .search__submit {
    width: 40px;
    height: 40px;
  }
}
#disp.srch {
  width: var(--deskWidth) !important;
  max-width: var(--deskMaxWidth) !important;
  padding-top: 200px !important;
  padding-bottom: 200px !important;
  display: block !important;
}
@media (max-width: 767px) {
  #disp.srch {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#disp.srch .disp-wrapper form {
  width: 862px;
  max-width: var(--deskMaxWidth);
}
#disp.srch .head .ctrl a {
  float: right;
  margin-right: 30px;
  font-size: 0;
  line-height: 0;
  color: #fff !important;
  text-indent: 0;
  text-decoration: none;
  width: auto;
  height: auto;
  background: none !important;
}
#disp.srch .head .ctrl a:before {
  content: 'Suche schliessen';
  display: block;
  color: #fff !important;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #fff, #fff);
  transition: all 0.24s;
}
@media (max-width: 767px) {
  #disp.srch .head .ctrl a:before {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.fr #disp.srch .head .ctrl a:before {
  content: 'Fermer la recherche';
}
.it #disp.srch .head .ctrl a:before {
  content: 'Chiudere la ricerca';
}
#disp.srch .head .ctrl a:hover:before {
  background-size: 100% 2px;
}
@media (max-width: 767px) {
  #disp.srch .head .ctrl a {
    margin-right: 0;
  }
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch .body fieldset {
  position: relative;
}
#disp.srch .body fieldset:after {
  content: '';
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 48px;
  height: 48px;
  border-radius: 10000px;
  background-color: #007D34;
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-search-white.svg);
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.srch .body fieldset:after {
    width: 40px;
    height: 40px;
  }
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Flama', sans-serif;
  background-color: #fff;
  margin-top: 40px;
  width: 100%;
  border-radius: 10000px;
  color: #191F24;
  height: 64px;
  box-sizing: border-box;
  padding: 0 64px;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  position: relative;
}
#disp.srch input.text:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  width: 40px;
  height: 40px;
  background-color: #005997;
}
#disp.srch input.text::placeholder {
  font-style: italic;
  color: #191F24;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    height: 56px;
    padding: 0 56px 0 32px;
  }
}
.cb-result {
  float: left;
  width: calc(100% - 60px);
  margin: 16px 30px 0;
  font-size: 14px;
  line-height: 1.71428571;
  background-color: #fff;
  text-align: left;
  box-sizing: border-box;
  padding: 18px 24px;
  border-radius: 5px;
  -webkit-font-smoothing: antialiased;
}
.cb-result:empty {
  display: none;
}
.cb-result .flag {
  font-weight: 700;
  color: #191F24;
  background-color: transparent;
}
@media (max-width: 767px) {
  .cb-result {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.cb-result p {
  display: block;
  margin: 12px 0;
}
.cb-result a {
  float: left;
  color: #007D34 !important;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #007D34, #007D34);
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .cb-result a {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.cb-result a.path {
  background-size: 100% 2px;
}
@media (min-width: 768px) {
  .cb-result a:hover {
    background-size: 100% 2px;
  }
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  color: #005997;
  text-align: left;
  box-sizing: border-box;
  padding: 12px 0 0;
}
@media (max-width: 767px) {
  .cb-result th {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
  display: none;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E6EBEE;
  box-sizing: border-box;
  padding: 0 0 12px;
}
.cb-result td:first-child {
  display: none;
}
.cb-result tr:last-child td {
  border: none;
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
.unit.form {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.unit.form.unit--spaceTop200 {
  margin-top: calc(var(--altVerticalSpace200) - var(--spaceTotal));
}
.unit.form.unit--spaceBottom200 {
  margin-bottom: calc(var(--altVerticalSpace200) - var(--spaceTotal));
}
.unit.form.unit--spaceTop70 {
  margin-top: calc((var(--spaceTotal) - var(--altVerticalSpace70)) * -1);
}
.unit.form.unit--spaceBottom70 {
  margin-bottom: calc((var(--spaceTotal) - var(--altVerticalSpace70)) * -1);
}
.unit.form.unit--spaceTop16 {
  margin-top: calc((var(--spaceUnit) - var(--spacePart)) * -1);
}
.unit.form.unit--spaceBottom16 {
  margin-bottom: calc((var(--spaceUnit) - var(--spacePart)) * -1);
}
.unit.form .unit__body {
  margin-top: -16px;
  margin-bottom: -16px;
}
.unit.form .unit__foot {
  margin-top: 32px;
}
.unit.form .part {
  margin-top: 16px;
  margin-bottom: 16px;
}
.unit.form form {
  float: left;
  width: 100%;
}
.unit.form .part.must {
  font-size: 16px;
  line-height: 1.375;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  font-size: 12px;
  line-height: 1.58333333;
  font-weight: 700;
  color: #005997;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 60px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #E6EBEE;
  min-height: 60px;
  padding: 12px 24px;
  appearance: none;
  border-radius: 5px;
  overflow: hidden;
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #007D34, #007D34);
  transition: all 0.24s;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form input.text:focus,
.unit.form textarea:focus {
  background-size: 100% 3px;
}
.unit.form textarea {
  padding: 16px 24px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 28px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.part--choiceAlignmentBeside .ctrl {
    width: calc(100% + 40px);
    margin-left: -20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .unit.form div.tick.part--choiceAlignmentBeside .ctrl > div {
    width: calc(50% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 16px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Flama', sans-serif;
  font-size: 18px;
  line-height: 1.55555556;
  background: #E6EBEE;
  height: 60px;
  border: 1px solid #191F24;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop span::file-selector-button {
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
  margin-right: 16px;
}
@media (max-width: 767px) {
  .unit.form .ship div.chop span::file-selector-button {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.unit.form .ship div.chop span::file-selector-button:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.unit.form .ship div.chop span::file-selector-button:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .unit.form .ship div.chop span::file-selector-button:hover {
    color: #007D34 !important;
  }
  .unit.form .ship div.chop span::file-selector-button:hover:before {
    width: 0;
  }
  .unit.form .ship div.chop span::file-selector-button:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .unit.form .ship div.chop span::file-selector-button {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: left;
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .unit.form .submit {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.unit.form .submit:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.unit.form .submit:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .unit.form .submit:hover {
    color: #007D34 !important;
  }
  .unit.form .submit:hover:before {
    width: 0;
  }
  .unit.form .submit:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .unit.form .submit {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #191F24;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  text-align: left;
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .two-step-verification-container a {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.two-step-verification-container a:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.two-step-verification-container a:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .two-step-verification-container a:hover {
    color: #007D34 !important;
  }
  .two-step-verification-container a:hover:before {
    width: 0;
  }
  .two-step-verification-container a:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .two-step-verification-container a {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table strong {
  font-weight: 500;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #E6EBEE;
  border-bottom: 1px solid #E6EBEE;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 14px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 700;
}
.table td {
  padding: 14px 10px;
  vertical-align: top;
  box-sizing: border-box;
}
.table td.init {
  padding-left: 0;
}
.part--tableFirstColFixTrue .table td.init {
  width: 270px;
}
.table td.exit {
  padding-right: 0;
}
@media (max-width: 767px) {
  .part--tableFirstColFixTrue .table td.init {
    width: 100px;
  }
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 700;
}
@media (max-width: 767px) {
  .part--table .table {
    display: block;
  }
  .part--table .table .table-head,
  .part--table .table .table-foot {
    display: none;
  }
  .part--table .table .table-body {
    display: block;
    width: 100%;
  }
  .part--table .table tr {
    float: left;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 8px 0;
    border-top: 1px solid #E6EBEE;
    border-bottom: none;
  }
  .part--table .table tr:last-child {
    border-bottom: 1px solid #E6EBEE;
  }
  .part--table .table .table-body tr:first-child {
    margin-top: 0;
  }
  .part--table .table td {
    float: left;
    display: block;
    width: 100% !important;
    padding: 0;
  }
  .part--table .table.table--headline td {
    position: relative;
    box-sizing: border-box;
    padding-left: 52%;
  }
  .part--table .table.table--headline td:before {
    content: attr(data-title);
    position: absolute;
    left: 0;
    top: 0;
    width: 48%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    font-weight: 700;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
body.cb-toggle-target-active  {
  overflow: hidden;
}
.logo {
  float: left;
  height: 84px;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .logo {
    height: 72px;
  }
}
@media (max-width: 767px) {
  .logo {
    height: 34px;
  }
}
.logo__image {
  width: auto;
  height: 100%;
}
@media (max-width: 767px) {
  .logo__image {
    height: calc(100% + 11px);
    margin-bottom: -11px;
  }
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Flama', sans-serif;
  color: #191F24;
  accent-color: #005997;
  overflow: hidden;
  hyphens: none;
  font-size: 18px;
  line-height: 1.55555556;
  font-weight: 400;
  box-sizing: border-box;
  border-top: var(--headerHeight) solid transparent;
  position: relative;
}
@media (max-width: 767px) {
  .wrapper {
    font-size: 16px;
    line-height: 1.625;
  }
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  background-color: #fff;
  box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.04), 0px 4px 6px -2px rgba(0, 0, 0, 0.01);
}
.section--pfad {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}
.layout6 .section--pfad,
.layout8 .section--pfad {
  display: none !important;
}
.section--pictBlock {
  background-color: #E6EBEE;
  overflow: hidden;
}
.section--multimood {
  display: none;
}
.layout2 .section--multimood,
.layout6 .section--multimood,
.layout8 .section--multimood {
  display: block;
}
.section--contact {
  margin-top: calc(var(--altVerticalSpace200) - var(--spaceTotal));
}
#view .section--suggestProductTeaserSlider:has(.ns-productSlider__area:empty) {
  display: none;
}
#view .section--referenceTeaserSlider:has(.ns-referenceSlider__area:empty) {
  display: none;
}
.content {
  float: left;
  width: 100%;
}
.section--header .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--headerHeight);
}
.subcontent {
  float: left;
  width: 100%;
}
.pfad {
  float: left;
  width: calc(100% + var(--deskSpace));
  margin-top: calc(var(--spacePart) * 2);
  font-size: 12px;
  line-height: 1.58333333;
  font-weight: 700;
  font-weight: 500;
  color: #007D34;
}
@media (max-width: 767px) {
  .pfad {
    width: 100vw;
    margin-left: calc(var(--deskSpace) * -1);
    position: relative;
  }
  .pfad:before,
  .pfad:after {
    content: '';
    position: absolute;
    top: 0;
    z-index: 2;
    width: var(--deskSpace);
    height: 100%;
  }
  .pfad:before {
    left: 0;
    background-image: linear-gradient(to right, #fff, transparent);
  }
  .pfad:after {
    right: 0;
    background-image: linear-gradient(to left, #fff, transparent);
  }
  .layout2 .pfad:before,
  .layout6 .pfad:before,
  .layout8 .pfad:before {
    background-image: linear-gradient(to right, #E6EBEE, transparent);
  }
  .layout2 .pfad:after,
  .layout6 .pfad:after,
  .layout8 .pfad:after {
    background-image: linear-gradient(to left, #E6EBEE, transparent);
  }
}
.pfad__wrapper {
  float: left;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  display: flex;
}
.pfad__wrapper::-webkit-scrollbar {
  display: none;
}
@media (max-width: 767px) {
  .pfad__wrapper {
    box-sizing: border-box;
    padding: 0 var(--deskSpace);
  }
}
.pfad a {
  white-space: nowrap;
  text-decoration: none;
  color: #007D34;
  display: inline-block;
  padding: 0 6px;
}
.pfad a:first-child {
  padding-left: 0;
}
.pfad a:last-child {
  padding-right: 0;
}
.pfad a:hover {
  color: #006128;
}
.pfad a.here {
  color: #191F24;
}
.titles {
  float: left;
  width: 100%;
  margin-top: var(--altVerticalSpace120);
  margin-bottom: var(--spaceTotal);
  display: flex;
  flex-direction: column;
}
.section--pictBlock .titles {
  margin-top: 0;
  margin-bottom: 0;
}
.cbdModule--titleOverline {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  color: #005997;
  margin-bottom: calc(var(--spacePart) * 2);
}
@media (max-width: 767px) {
  .cbdModule--titleOverline {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.cbdModule--titleLead {
  margin-top: var(--altVerticalSpace40);
}
.cbdModule--titleAltName {
  margin-top: var(--altVerticalSpace40);
  margin-bottom: calc((var(--altVerticalSpace40) - (var(--spacePart) * 2)) * -1);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-weight: 400;
  font-style: italic;
}
@media (max-width: 767px) {
  .cbdModule--titleAltName {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.sytecoLabel {
  position: absolute;
  left: 100%;
  margin-left: 142px;
  bottom: 80px;
  z-index: 2;
  width: 188px;
}
@media (max-width: 1199px) {
  .sytecoLabel {
    left: 0;
    bottom: initial;
    top: 100%;
    margin-left: 0;
    margin-top: 154px;
  }
}
@media (max-width: 767px) {
  .sytecoLabel {
    width: 112px;
    margin-top: 60px;
  }
}
.cbdModule--teaserDescription {
  float: left;
  width: 100%;
  margin-top: var(--altVerticalSpace40);
  position: relative;
}
#edit .cbdModule--teaserDescription {
  padding-top: 34px;
}
#edit .cbdModule--teaserDescription:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1.33333333;
  font-weight: 700;
  min-height: 24px;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 1px;
  background-color: #71CAF3;
  color: #fff;
  padding: 4px 10px;
}
#edit .cbdModule--teaserDescription:before {
  content: 'Text des Teasers – nicht sichtbar im Ansichtsmodus';
}
#edit .cbdModule--teaserDescription:not(.is-marked) {
  outline: 2px dashed #ccc;
  outline-offset: 3px;
}
#view .cbdModule--teaserDescription {
  display: none;
}
.cbdModule--sliderTitle1,
.cbdModule--sliderTitle2 {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  color: #005997;
  margin-top: calc((var(--altVerticalSpace200) - var(--spaceTotal)));
  margin-bottom: calc(((var(--altVerticalSpace200) - var(--spaceTotal)) - (var(--spacePart) * 2)) * -1);
}
@media (max-width: 767px) {
  .cbdModule--sliderTitle1,
  .cbdModule--sliderTitle2 {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.downlink {
  position: absolute;
  left: -24px;
  top: 100%;
  margin-top: calc((24px + 56px) * -1);
  z-index: 5;
  color: #007D34 !important;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #007D34, #007D34);
  transition: all 0.24s;
  cursor: pointer;
  margin-left: 24px;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  display: none;
}
@media (max-width: 767px) {
  .downlink {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.downlink.path {
  background-size: 100% 2px;
}
@media (min-width: 768px) {
  .downlink:hover {
    background-size: 100% 2px;
  }
}
.layout6 .downlink,
.layout8 .downlink {
  display: block;
}
.downlink:after {
  content: '';
  position: absolute;
  left: -24px;
  top: 0;
  height: 100%;
  width: 24px;
  background-color: #007D34;
  mask-size: 16px auto;
  mask-position: 0 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-left.svg);
}
@media (max-width: 1199px) {
  .downlink {
    margin-top: 222px;
  }
}
@media (max-width: 767px) {
  .downlink {
    margin-top: 80px;
  }
}
#cb-cookie-warning {
  position: fixed;
  left: 40px;
  bottom: 40px;
  max-width: calc(100% - 80px);
  width: 390px;
  z-index: 2100;
  font-family: 'Flama', sans-serif;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 400;
  color: #191F24;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}
@media (max-width: 767px) {
  #cb-cookie-warning {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
#cb-cookie-warning a {
  text-decoration: underline;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
@media (max-width: 1199px) {
  #cb-cookie-warning {
    left: var(--deskSpace);
    bottom: var(--deskSpace);
    max-width: var(--deskMaxWidth);
  }
}
@media (max-width: 767px) {
  #cb-cookie-warning {
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
}
.cb-cookie-warning--text h5 {
  padding-bottom: 6px;
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 24px 24px;
}
@media (max-width: 767px) {
  .cb-cookie-warning--actions {
    justify-content: flex-start;
  }
}
.cb-cookie-warning__button {
  float: left;
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
  padding-left: 24px;
  color: #007D34 !important;
}
@media (max-width: 767px) {
  .cb-cookie-warning__button {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.cb-cookie-warning__button:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.cb-cookie-warning__button:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .cb-cookie-warning__button:hover {
    color: #007D34 !important;
  }
  .cb-cookie-warning__button:hover:before {
    width: 0;
  }
  .cb-cookie-warning__button:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .cb-cookie-warning__button {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.cb-cookie-warning__button:before {
  right: initial;
  left: -10px;
  width: 0;
  background-color: var(--buttonSecondaryOverlay);
}
.cb-cookie-warning__button:after {
  display: none;
}
@media (min-width: 768px) {
  .cb-cookie-warning__button:hover:before {
    width: calc(100% + 20px);
  }
}
#cb-cookie-warning__button--decline {
  order: 2;
}
@media (max-width: 767px) {
  #cb-cookie-warning__button--decline {
    margin-left: 24px;
  }
}
#cb-cookie-warning__button--accept {
  order: 1;
  background-color: #007D34;
  color: #fff !important;
}
#cb-cookie-warning__button--accept:before {
  background-color: #006128;
}
.focus-overlay {
  display: none;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 0 22px #0057FF;
  transition: all 0.2s cubic-bezier(0, 1, 0, 1);
  border-radius: 5px;
}
.focus-overlay.focus-overlay-active {
  display: block;
}
.rootVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + ((100vw - var(--deskWidth)) / 2));
  height: 100%;
}
@media (max-width: 1440px) {
  .rootVideo {
    width: calc(100% + var(--deskSpace));
  }
}
.rootVideo__video {
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
a {
  color: inherit;
  text-decoration: underline;
  text-underline-offset: 3px;
}
a:hover,
a:focus {
  color: #005997;
}
h1,
h2 {
  font-size: 45px;
  line-height: 1.22222222;
  font-weight: 700;
}
@media (max-width: 767px) {
  h1,
  h2 {
    font-size: 32px;
    line-height: 1.3125;
  }
}
h3 {
  font-size: 35px;
  line-height: 1.28571429;
  font-weight: 700;
}
@media (max-width: 767px) {
  h3 {
    font-size: 24px;
    line-height: 1.41666667;
  }
}
h4 {
  font-size: 26px;
  line-height: 1.30769231;
  font-weight: 700;
}
@media (max-width: 767px) {
  h4 {
    font-size: 20px;
    line-height: 1.5;
  }
}
h5 {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
}
@media (max-width: 767px) {
  h5 {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
h6 {
  font-size: 18px;
  line-height: 1.55555556;
  font-weight: 500;
}
@media (max-width: 767px) {
  h6 {
    font-size: 16px;
    line-height: 1.625;
  }
}
.loud {
  font-size: 18px;
  line-height: 1.55555556;
  font-weight: 500;
}
@media (max-width: 767px) {
  .loud {
    font-size: 16px;
    line-height: 1.625;
  }
}
.pale {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 400;
}
@media (max-width: 767px) {
  .pale {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.skew {
  display: inline;
  background-color: #005997;
  border-radius: 10000px;
  color: #fff;
  box-decoration-break: clone;
  padding: 5px 12px;
  font-size: 12px;
  line-height: 2.33333333;
  font-weight: 700;
}
.skew a:hover {
  color: #E6EBEE;
}
.area--two .unitThree .skew {
  font-size: 16px;
  line-height: 1.875;
  padding-top: 3px;
  padding-bottom: 3px;
}
.star {
  color: #005997;
}
.area--one {
  display: flex;
  flex-wrap: wrap;
}
.layout2 .area--one.area1,
.layout6 .area--one.area1,
.layout8 .area--one.area1 {
  margin-top: var(--spaceTotal);
}
.area--one .unitOne--1-1 .part--link {
  margin-top: calc(var(--altVerticalSpace56) - var(--spacePart));
}
.area--one .unitOne--showSytecoLabelTrue {
  position: relative;
  padding-bottom: calc(80px - var(--spacePart));
  margin-bottom: var(--spaceTotal);
}
.area--one .unitOne--showSytecoLabelTrue:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 152px;
  aspect-ratio: 1.09615385;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/syteco-label-ohneBG.svg);
}
@media (max-width: 767px) {
  .area--one .unitOne--showSytecoLabelTrue {
    padding-bottom: calc(140px - var(--spacePart));
  }
  .area--one .unitOne--showSytecoLabelTrue:after {
    width: 128px;
  }
}
.area--two {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.area--two .unitTwo {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  background-color: #E6EBEE;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.area--two .unitTwo + .unitTwo + .unitTwo + .unitTwo {
  margin-top: calc((var(--spaceTotal) - 24px) * -1);
}
.area--two .unitTwo .unit__background {
  height: 242px;
}
.area--two .unitTwo .unit__background * {
  height: 100%;
}
.area--two .unitTwo .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--two .unitTwo .unit__background .placeholder {
  padding-bottom: 0;
}
.area--two .unitTwo .unit__background .unit__backgroundImage {
  object-position: center top;
}
.area--two .unitTwo .unit__content {
  box-sizing: border-box;
  padding: calc(var(--generalUnitPadding) - 10px) var(--generalUnitPadding);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.area--two .unitTwo .unit__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.area--two .unitTwo .part {
  margin-top: 10px;
  margin-bottom: 10px;
}
.area--two .unitTwo .part--linkTextlink + .part--linkTextlink {
  margin-top: -6px;
}
.area--two .unitTwo .part--file:last-child {
  padding-top: 10px;
  margin-top: auto;
}
@media (max-width: 1199px) {
  .area--two .unitTwo + .unitTwo + .unitTwo {
    margin-top: calc((var(--spaceTotal) - 24px) * -1);
  }
}
@media (max-width: 767px) {
  .area--two .unitTwo + .unitTwo {
    margin-top: calc((var(--spaceTotal) - 24px) * -1);
  }
}
.area--two .unitThree {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.area--two .unitThree + .unitThree + .unitThree + .unitThree + .unitThree {
  margin-top: calc((var(--spaceTotal) - 24px) * -1);
}
.area--two .unitThree .unit__background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}
.area--two .unitThree .unit__background * {
  height: 100%;
}
.area--two .unitThree .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--two .unitThree .unit__background .placeholder {
  padding-bottom: 0;
}
.area--two .unitThree .unit__background .unit__backgroundImage {
  transition: all 0.24s;
}
.area--two .unitThree .unit__content {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: calc(20px - var(--spacePart)) 20px;
  min-height: 180px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.area--two .unitThree.unit--isAnchor:hover .unit__background .unit__backgroundImage {
  transform: scale(1.05);
}
@media (max-width: 1199px) {
  .area--two .unitThree + .unitThree + .unitThree {
    margin-top: calc((var(--spaceTotal) - 24px) * -1);
  }
}
@media (max-width: 767px) {
  .area--two .unitThree + .unitThree {
    margin-top: calc((var(--spaceTotal) - 24px) * -1);
  }
}
.area--two .unitFour {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.area--two .unitFour + .unitFour + .unitFour {
  margin-top: calc((var(--spaceTotal) - 24px) * -1);
}
.area--two .unitFour .unit__background {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.area--two .unitFour .unit__background * {
  height: 100%;
}
.area--two .unitFour .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--two .unitFour .unit__background .placeholder {
  padding-bottom: 0;
}
.area--two .unitFour .unit__background:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 60%;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  opacity: 0.8;
}
.area--two .unitFour .unit__content {
  position: relative;
  z-index: 2;
  flex-grow: 1;
  box-sizing: border-box;
  padding: calc(40px - var(--spacePart)) 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: var(--galleryImageHeight);
}
.area--two .unitFour.unit--isAnchor {
  color: #fff;
}
.area--two .unitFour.unit--isAnchor .unit__background {
  overflow: hidden;
}
.area--two .unitFour.unit--isAnchor .unit__background .unit__backgroundImage {
  transition: all 0.24s;
}
.area--two .unitFour.unit--isAnchor:hover .unit__background .unit__backgroundImage {
  transform: scale(1.05);
}
@media (max-width: 1199px) {
  .area--two .unitFour + .unitFour {
    margin-top: calc((var(--spaceTotal) - 24px) * -1);
  }
}
@media (max-width: 767px) {
  .area--two .unitFour .unit__content {
    padding: calc(24px - var(--spacePart)) 24px;
  }
}
.area--two .unitFive {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  display: flex;
  justify-content: flex-end;
  position: relative;
  max-width: 1120px;
}
.area--two .unitFive.unit--spaceTop200 {
  margin-top: calc(var(--altVerticalSpace200) - var(--spaceTotal));
}
.area--two .unitFive.unit--spaceBottom200 {
  margin-bottom: calc(var(--altVerticalSpace200) - var(--spaceTotal));
}
.area--two .unitFive.unit--spaceTop70 {
  margin-top: calc((var(--spaceTotal) - var(--altVerticalSpace70)) * -1);
}
.area--two .unitFive.unit--spaceBottom70 {
  margin-bottom: calc((var(--spaceTotal) - var(--altVerticalSpace70)) * -1);
}
.area--two .unitFive.unit--spaceTop16 {
  margin-top: calc((var(--spaceUnit) - var(--spacePart)) * -1);
}
.area--two .unitFive.unit--spaceBottom16 {
  margin-bottom: calc((var(--spaceUnit) - var(--spacePart)) * -1);
}
.area--two .unitFive:before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 1;
  background-color: #E6EBEE;
}
.area--two .unitFive .unit__content {
  width: 686px;
  flex-shrink: 0;
  z-index: 3;
  box-sizing: border-box;
  padding-left: 24px;
  position: relative;
  min-height: var(--moodHeight);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.area--two .unitFive .unit__content:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #E6EBEE;
  transform-origin: 0 100%;
  transform: skew(calc(var(--generalAngle) * -1));
}
.area--two .unitFive .unit__background {
  position: relative;
  z-index: 2;
  width: calc((100% - (662px + 24px)) + ((100vw - var(--deskWidth)) / 2));
  flex-shrink: 0;
}
.area--two .unitFive .unit__background .unit__backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.area--two .unitFive .unit__body {
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
}
.area--two .unitFive .part--link {
  margin-top: calc(var(--altVerticalSpace70) - var(--spacePart));
}
.area--two .unitFive .part:first-child {
  margin-top: 0 !important;
}
.area--two .unitFive .part:last-child {
  margin-bottom: 0 !important;
}
@media (max-width: 1199px) {
  .area--two .unitFive {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .area--two .unitFive .unit__background {
    order: 2;
    width: 100vw;
    height: var(--moodHeight);
  }
  .area--two .unitFive .unit__content {
    width: 100%;
    order: 1;
    min-height: 0;
    padding: 0;
  }
  .area--two .unitFive .unit__content:before {
    left: 50%;
    width: 100vw;
    transform-origin: 100% 100%;
    transform: translateX(-50%) skewY(var(--generalAngle));
  }
  .area--two .unitFive .unit__body {
    margin-bottom: 0;
  }
}
.area--two .unit--imageGallery {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.area--two .unit--imageGallery.unit--spaceTop200 {
  margin-top: calc(var(--altVerticalSpace200) - var(--spaceTotal));
}
.area--two .unit--imageGallery.unit--spaceBottom200 {
  margin-bottom: calc(var(--altVerticalSpace200) - var(--spaceTotal));
}
.area--two .unit--imageGallery.unit--spaceTop70 {
  margin-top: calc((var(--spaceTotal) - var(--altVerticalSpace70)) * -1);
}
.area--two .unit--imageGallery.unit--spaceBottom70 {
  margin-bottom: calc((var(--spaceTotal) - var(--altVerticalSpace70)) * -1);
}
.area--two .unit--imageGallery.unit--spaceTop16 {
  margin-top: calc((var(--spaceUnit) - var(--spacePart)) * -1);
}
.area--two .unit--imageGallery.unit--spaceBottom16 {
  margin-bottom: calc((var(--spaceUnit) - var(--spacePart)) * -1);
}
.area--two .unit--imageGallery .unit__body {
  width: calc(100% + 24px);
  margin: -12px;
}
.area--two .unit--imageGallery .part {
  margin: 12px;
  width: calc(100% - 24px);
}
.area--two .unit--imageGallery .part.pict {
  height: var(--galleryImageHeight);
  position: relative;
}
.area--two .unit--imageGallery .part.pict * {
  height: 100%;
}
.area--two .unit--imageGallery .part.pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--two .unit--imageGallery .part.pict .placeholder {
  padding-bottom: 0;
}
.area--two .unit--imageGallery .part.pict:has(.cb-image-caption) .cb-image-container:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 60%;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  opacity: 0.8;
}
.area--two .unit--imageGallery .part.pict .cb-image-caption {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
  height: auto;
  box-sizing: border-box;
  padding: var(--generalUnitPadding);
  margin-top: 0;
  color: #fff;
  font-style: italic;
}
.area .unit--spaceTop200 {
  margin-top: calc((var(--altVerticalSpace200) - (var(--spaceTotal) + var(--spacePart))));
}
.area .unit--spaceBottom200 {
  margin-bottom: calc((var(--altVerticalSpace200) - (var(--spaceTotal) + var(--spacePart))));
}
.area .unit--spaceTop70 {
  margin-top: calc(((var(--spaceTotal) + var(--spacePart)) - var(--altVerticalSpace70)) * -1);
}
.area .unit--spaceBottom70 {
  margin-bottom: calc(((var(--spaceTotal) + var(--spacePart)) - var(--altVerticalSpace70)) * -1);
}
.area .unit--spaceTop16 {
  margin-top: calc(var(--spaceUnit) * -1);
}
.area .unit--spaceBottom16 {
  margin-bottom: calc(var(--spaceUnit) * -1);
}
.area .unit--fold {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  border-top: 1px solid #E6EBEE;
  border-bottom: 1px solid #E6EBEE;
}
.area .unit--fold + .unit--fold {
  margin-top: calc((var(--spaceTotal) + 1px) * -1);
}
.area .unit--fold div.less .part {
  margin-top: 0;
  margin-bottom: 0;
}
.area .unit--fold div.more {
  display: block !important;
  height: 0;
  overflow: hidden;
  transition: all 0.6s;
  box-sizing: border-box;
  padding-left: 64px;
}
.area .unit--fold div.more .part {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.2s, transform 0.2s;
  margin-top: 25px;
  margin-bottom: 25px;
}
.area .unit--fold div.more .part:last-child {
  margin-bottom: 90px;
}
.area .unit--fold .fold-toggle {
  display: block;
  padding: 19px 0 19px 64px;
  text-decoration: none;
  position: relative;
  color: #191F24;
}
.area .unit--fold .fold-toggle:before,
.area .unit--fold .fold-toggle:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 34px;
  height: 34px;
  border-radius: 10000px;
}
.area .unit--fold .fold-toggle:before {
  background-color: #E6F3EB;
}
.area .unit--fold .fold-toggle:after {
  background-color: #007D34;
  mask-size: 16px 16px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-plus.svg);
}
.area .unit--fold .fold-toggle:hover:before {
  background-color: #B0DAC2;
}
.area .unit--fold .fold-toggle.fold-toggle--open:after {
  mask-image: url(/images/icon-minus.svg);
}
.area .unit--fold .part--loadTextlink + .part--loadTextlink,
.area .unit--fold .part--linkDownload + .part--loadTextlink,
.area .unit--fold .part--loadTextlink + .part--linkDownload,
.area .unit--fold .part--linkDownload + .part--linkDownload {
  margin-top: -15px !important;
}
.area .unit--fold .unit__foot {
  display: none;
}
.area .unit--fold.unit--foldOpen div.more {
  height: var(--js-elementHeight);
}
.area .unit--fold.unit--foldOpen div.more .part {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.4s 0.2s, transform 0.4s 0.2s;
}
@media (max-width: 767px) {
  .area .unit--fold div.more {
    padding-left: 0;
  }
  .area .unit--fold .fold-toggle {
    padding: 16px 0 16px 48px;
  }
  .area .unit--fold .fold-toggle:before,
  .area .unit--fold .fold-toggle:after {
    width: 26px;
    height: 26px;
  }
  .area .unit--fold .fold-toggle:after {
    mask-size: 12px 12px;
  }
}
.part--linkButtonPrimary .open,
.part--loadButtonPrimary .open,
.part--linkButtonPrimary .load,
.part--loadButtonPrimary .load {
  float: left;
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .part--linkButtonPrimary .open,
  .part--loadButtonPrimary .open,
  .part--linkButtonPrimary .load,
  .part--loadButtonPrimary .load {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.part--linkButtonPrimary .open:before,
.part--loadButtonPrimary .open:before,
.part--linkButtonPrimary .load:before,
.part--loadButtonPrimary .load:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.part--linkButtonPrimary .open:after,
.part--loadButtonPrimary .open:after,
.part--linkButtonPrimary .load:after,
.part--loadButtonPrimary .load:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .part--linkButtonPrimary .open:hover,
  .part--loadButtonPrimary .open:hover,
  .part--linkButtonPrimary .load:hover,
  .part--loadButtonPrimary .load:hover {
    color: #007D34 !important;
  }
  .part--linkButtonPrimary .open:hover:before,
  .part--loadButtonPrimary .open:hover:before,
  .part--linkButtonPrimary .load:hover:before,
  .part--loadButtonPrimary .load:hover:before {
    width: 0;
  }
  .part--linkButtonPrimary .open:hover:after,
  .part--loadButtonPrimary .open:hover:after,
  .part--linkButtonPrimary .load:hover:after,
  .part--loadButtonPrimary .load:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .part--linkButtonPrimary .open,
  .part--loadButtonPrimary .open,
  .part--linkButtonPrimary .load,
  .part--loadButtonPrimary .load {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.part--linkButtonSecondary .open,
.part--loadButtonSecondary .open,
.part--linkButtonSecondary .load,
.part--loadButtonSecondary .load {
  float: left;
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
  padding-left: 24px;
  color: #007D34 !important;
}
@media (max-width: 767px) {
  .part--linkButtonSecondary .open,
  .part--loadButtonSecondary .open,
  .part--linkButtonSecondary .load,
  .part--loadButtonSecondary .load {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.part--linkButtonSecondary .open:before,
.part--loadButtonSecondary .open:before,
.part--linkButtonSecondary .load:before,
.part--loadButtonSecondary .load:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.part--linkButtonSecondary .open:after,
.part--loadButtonSecondary .open:after,
.part--linkButtonSecondary .load:after,
.part--loadButtonSecondary .load:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .part--linkButtonSecondary .open:hover,
  .part--loadButtonSecondary .open:hover,
  .part--linkButtonSecondary .load:hover,
  .part--loadButtonSecondary .load:hover {
    color: #007D34 !important;
  }
  .part--linkButtonSecondary .open:hover:before,
  .part--loadButtonSecondary .open:hover:before,
  .part--linkButtonSecondary .load:hover:before,
  .part--loadButtonSecondary .load:hover:before {
    width: 0;
  }
  .part--linkButtonSecondary .open:hover:after,
  .part--loadButtonSecondary .open:hover:after,
  .part--linkButtonSecondary .load:hover:after,
  .part--loadButtonSecondary .load:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .part--linkButtonSecondary .open,
  .part--loadButtonSecondary .open,
  .part--linkButtonSecondary .load,
  .part--loadButtonSecondary .load {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.part--linkButtonSecondary .open:before,
.part--loadButtonSecondary .open:before,
.part--linkButtonSecondary .load:before,
.part--loadButtonSecondary .load:before {
  right: initial;
  left: -10px;
  width: 0;
  background-color: var(--buttonSecondaryOverlay);
}
.part--linkButtonSecondary .open:after,
.part--loadButtonSecondary .open:after,
.part--linkButtonSecondary .load:after,
.part--loadButtonSecondary .load:after {
  display: none;
}
@media (min-width: 768px) {
  .part--linkButtonSecondary .open:hover:before,
  .part--loadButtonSecondary .open:hover:before,
  .part--linkButtonSecondary .load:hover:before,
  .part--loadButtonSecondary .load:hover:before {
    width: calc(100% + 20px);
  }
}
.part--linkTextlink + .part--linkTextlink {
  margin-top: calc((var(--spacePart) - 4px) * -1) !important;
}
.part--linkTextlink .open {
  float: left;
  color: #007D34 !important;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #007D34, #007D34);
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .part--linkTextlink .open {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.part--linkTextlink .open.path {
  background-size: 100% 2px;
}
@media (min-width: 768px) {
  .part--linkTextlink .open:hover {
    background-size: 100% 2px;
  }
}
.part--loadTypeButton .load {
  float: left;
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
  padding-left: 24px;
  color: #007D34 !important;
}
@media (max-width: 767px) {
  .part--loadTypeButton .load {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.part--loadTypeButton .load:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.part--loadTypeButton .load:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .part--loadTypeButton .load:hover {
    color: #007D34 !important;
  }
  .part--loadTypeButton .load:hover:before {
    width: 0;
  }
  .part--loadTypeButton .load:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .part--loadTypeButton .load {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.part--loadTypeButton .load:before {
  right: initial;
  left: -10px;
  width: 0;
  background-color: var(--buttonSecondaryOverlay);
}
.part--loadTypeButton .load:after {
  display: none;
}
@media (min-width: 768px) {
  .part--loadTypeButton .load:hover:before {
    width: calc(100% + 20px);
  }
}
.part--linkDownload + .part--linkDownload,
.part--loadTextlink + .part--linkDownload,
.part--linkDownload + .part--loadTextlink,
.part--loadTextlink + .part--loadTextlink {
  border-top: 1px solid #E6EBEE;
  padding-top: 10px;
  margin-top: calc((var(--spacePart) - 10px) * -1);
}
.part--linkDownload .load,
.part--loadTextlink .load,
.part--linkDownload .open,
.part--loadTextlink .open {
  float: left;
  color: #007D34 !important;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #007D34, #007D34);
  transition: all 0.24s;
  margin-left: 32px;
  max-width: calc(100% - 32px);
  position: relative;
}
@media (max-width: 767px) {
  .part--linkDownload .load,
  .part--loadTextlink .load,
  .part--linkDownload .open,
  .part--loadTextlink .open {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.part--linkDownload .load.path,
.part--loadTextlink .load.path,
.part--linkDownload .open.path,
.part--loadTextlink .open.path {
  background-size: 100% 2px;
}
@media (min-width: 768px) {
  .part--linkDownload .load:hover,
  .part--loadTextlink .load:hover,
  .part--linkDownload .open:hover,
  .part--loadTextlink .open:hover {
    background-size: 100% 2px;
  }
}
.part--linkDownload .load:after,
.part--loadTextlink .load:after,
.part--linkDownload .open:after,
.part--loadTextlink .open:after {
  content: '';
  position: absolute;
  left: -32px;
  top: 0;
  z-index: 2;
  width: 32px;
  height: 100%;
  background-color: #007D34;
  mask-size: 17px auto;
  mask-position: 0 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-load.svg);
}
.part--loadVcardTrue .load {
  padding-left: 54px;
}
.part--loadVcardTrue .load:after {
  display: block;
  left: 0 !important;
  width: 54px !important;
  mask-size: auto 18px;
  mask-position: 24px 50%;
  mask-image: url(/images/icon-vcard.svg);
}
.part.pict .cb-image-container {
  border-radius: 5px;
  overflow: hidden;
}
.part--alignBeside {
  width: auto !important;
  min-width: 50px;
  margin-right: 40px !important;
}
.part--alignBeside:last-child {
  margin-right: 0 !important;
}
.part.cb-googlemaps,
.part.cb-googlemaps .cb-googlemapscontainer,
.part.cb-googlemaps .cb-undraggable {
  height: 447px !important;
}
@media (max-width: 1199px) {
  .part.cb-googlemaps,
  .part.cb-googlemaps .cb-googlemapscontainer,
  .part.cb-googlemaps .cb-undraggable {
    height: 347px !important;
  }
}
@media (max-width: 767px) {
  .part.cb-googlemaps,
  .part.cb-googlemaps .cb-googlemapscontainer,
  .part.cb-googlemaps .cb-undraggable {
    height: 165px !important;
  }
}
.text-section--skew {
  font-size: 12px;
  line-height: 2.33333333;
}
.navigationMain {
  float: left;
  display: flex;
  align-items: center;
}
.cbdModule--headerButton {
  float: left;
}
.cbdModule--headerButton .open {
  float: left;
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .cbdModule--headerButton .open {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.cbdModule--headerButton .open:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.cbdModule--headerButton .open:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .cbdModule--headerButton .open:hover {
    color: #007D34 !important;
  }
  .cbdModule--headerButton .open:hover:before {
    width: 0;
  }
  .cbdModule--headerButton .open:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .cbdModule--headerButton .open {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.section--header .cbdModule--headerButton {
  margin-left: 64px;
  margin-right: 36px;
}
@media (max-width: 1199px) {
  .section--header .cbdModule--headerButton {
    margin-right: 24px;
  }
}
@media (max-width: 767px) {
  .section--header .cbdModule--headerButton {
    display: none;
  }
}
.navigationMobile .cbdModule--headerButton {
  display: none;
  margin-top: 56px;
}
@media (max-width: 767px) {
  .navigationMobile .cbdModule--headerButton {
    display: block;
  }
}
.navigationMain .meta.find {
  float: left;
  flex-shrink: 0;
  width: 42px;
  height: 42px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-color: #191F24;
  mask-size: 18px 18px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-search.svg);
}
.navigationMain .meta.find:hover {
  background-color: #007D34;
}
.navigationMain div.navi {
  float: left;
}
.navigationMain div.navi .cb-toggle {
  display: none;
}
.navigationMain div.navi > .item {
  float: left;
}
.navigationMain div.navi > .item > .menu {
  display: block;
}
.navigationMain div.sub2 {
  display: flex;
}
.navigationMain div.sub2 > .item {
  margin-right: 36px;
  position: relative;
}
.navigationMain div.sub2 > .item.exit {
  margin-right: 0;
}
.navigationMain div.sub2 > .item:not(.item-empty) {
  padding-right: 18px;
}
.navigationMain div.sub2 > .item:not(.item-empty) > .cb-toggle {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.navigationMain div.sub2 > .item:not(.item-empty) > .cb-toggle:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-style: solid;
  border-color: transparent;
  border-top-color: #191F24;
  border-width: 6px 6px 0 6px;
  transition: all 0.24s;
}
.navigationMain div.sub2 > .item:not(.item-empty) > .cb-toggle.cb-toggle-active:after {
  transform: translateY(-50%) rotate(-180deg);
}
.navigationMain div.sub2 > .item:hover > .menu,
.navigationMain div.sub2 > .item.cb-toggle-target-active > .menu {
  background-size: 100% 2px;
}
.navigationMain div.sub2 > .item > .menu {
  white-space: nowrap;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  text-decoration: none;
  color: #191F24;
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #007D34, #007D34);
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .navigationMain div.sub2 > .item > .menu {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.navigationMain div.sub2 > .item > .menu.path {
  background-size: 100% 2px;
}
@media (min-width: 768px) {
  .navigationMain div.sub2 > .item > .menu:hover {
    background-size: 100% 2px;
  }
}
@media (max-width: 1199px) {
  .navigationMain div.sub2 {
    display: none !important;
  }
}
.navigationMain div.sub2 > .item--smallNavi div.sub3 {
  position: absolute;
  left: -70px;
  top: 100%;
  margin-top: 61px;
  background-color: #E6EBEE;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 24px 48px -12px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 70px;
  min-width: 410px;
  z-index: 3;
  height: 0;
  overflow: hidden;
  transition: all 0.6s;
}
.navigationMain div.sub2 > .item--smallNavi div.sub3 > .item {
  margin-top: 16px;
  transform: translateY(-20px);
  opacity: 0;
  transition: all 0.2s;
}
.navigationMain div.sub2 > .item--smallNavi div.sub3 > .item.init {
  margin-top: 56px;
}
.navigationMain div.sub2 > .item--smallNavi div.sub3 > .item.exit {
  margin-bottom: 56px;
}
.navigationMain div.sub2 > .item--smallNavi div.sub3 > .item > .menu {
  white-space: nowrap;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  color: #191F24;
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .navigationMain div.sub2 > .item--smallNavi div.sub3 > .item > .menu {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.navigationMain div.sub2 > .item--smallNavi div.sub3 > .item > .menu:hover,
.navigationMain div.sub2 > .item--smallNavi div.sub3 > .item > .menu.path {
  color: #007D34;
}
.navigationMain div.sub2 > .item--smallNavi.cb-toggle-target-active div.sub3 {
  height: var(--js-elementHeight);
}
.navigationMain div.sub2 > .item--smallNavi.cb-toggle-target-active div.sub3 > .item {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s 0.2s;
}
.navigationMain div.sub4 {
  display: none !important;
}
.bigNavi {
  position: fixed;
  left: 50%;
  top: var(--headerHeight);
  z-index: 3;
  transform: translateX(-50%);
  width: 1086px;
  max-width: var(--deskMaxWidth);
  background-color: #E6EBEE;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 24px 48px -12px rgba(0, 0, 0, 0.18);
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  max-height: calc(100% - (var(--headerHeight) + 70px));
  height: 0;
  transition: all 0.6s;
}
.bigNavi::-webkit-scrollbar {
  display: none;
}
.navigationMain div.sub2 > .item--bigNavi.cb-toggle-target-active .bigNavi {
  height: var(--js-elementHeight);
}
.bigNavi__content {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 56px 70px;
}
.bigNavi__category {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.2s;
}
.navigationMain div.sub2 > .item--bigNavi.cb-toggle-target-active .bigNavi__category {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s 0.2s;
}
.bigNavi__categoryAnchor {
  float: left;
  color: #007D34 !important;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #007D34, #007D34);
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .bigNavi__categoryAnchor {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.bigNavi__categoryAnchor.path {
  background-size: 100% 2px;
}
@media (min-width: 768px) {
  .bigNavi__categoryAnchor:hover {
    background-size: 100% 2px;
  }
}
.bigNavi__stage {
  float: left;
  width: 100%;
  margin-top: var(--altVerticalSpace40);
}
.bigNavi__area {
  float: left;
  width: calc(100% + 70px);
  margin-left: -35px;
  display: flex;
  flex-wrap: wrap;
}
.bigNavi__group {
  float: left;
  width: calc((100% / 3) - 70px);
  margin-left: 35px;
  margin-right: 35px;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.2s;
}
.navigationMain div.sub2 > .item--bigNavi.cb-toggle-target-active .bigNavi__group {
  opacity: 1;
  transform: translateY(0);
}
.navigationMain div.sub2 > .item--bigNavi.cb-toggle-target-active .bigNavi__group:nth-child(3n - 2) {
  transition: all 0.4s 0.2s;
}
.navigationMain div.sub2 > .item--bigNavi.cb-toggle-target-active .bigNavi__group:nth-child(3n - 1) {
  transition: all 0.4s 0.3s;
}
.navigationMain div.sub2 > .item--bigNavi.cb-toggle-target-active .bigNavi__group:nth-child(3n) {
  transition: all 0.4s 0.4s;
}
.bigNavi div.sub3 {
  width: 100%;
}
.bigNavi div.sub3 > .item {
  width: 100%;
  border-top: 1px solid #fff;
}
.bigNavi div.sub3 > .item.exit {
  border-bottom: 1px solid #fff;
}
.bigNavi div.sub3 > .item > .menu {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  text-decoration: none;
  color: #191F24;
  padding: 12px 0;
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .bigNavi div.sub3 > .item > .menu {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.bigNavi div.sub3 > .item > .menu:hover,
.bigNavi div.sub3 > .item > .menu:focus,
.bigNavi div.sub3 > .item > .menu.path {
  color: #007D34;
}
.navigationSide {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: var(--spaceTotal);
  display: none;
}
.layout3 .navigationSide,
.layout4 .navigationSide,
.layout5 .navigationSide {
  display: block;
}
@media (max-width: 1199px) {
  .navigationSide {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1500;
    width: 414px;
    box-sizing: border-box;
    margin: calc(var(--headerHeight) + 50px) 0 50px;
    height: calc(100% - (var(--headerHeight) + 50px + 50px));
    transform: translateX(100%);
    transition: all 0.6s;
  }
  .wrapper.cb-toggle-target-active .navigationSide {
    transform: translateX(0);
  }
}
@media (max-width: 767px) {
  .navigationSide {
    width: 312px;
    margin: calc(var(--headerHeight) + 80px) 0 80px;
  }
}
.sideNaviToggle {
  display: none;
  position: absolute;
  right: 100%;
  bottom: 100%;
  background-color: #007D34;
  color: #fff !important;
  white-space: nowrap;
  text-decoration: none;
  font-size: 12px;
  line-height: 1.66666667;
  font-weight: 700;
  padding: 5px 16px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transform-origin: 100% 100%;
  transform: rotate(-90deg);
}
@media (max-width: 1199px) {
  .sideNaviToggle {
    display: block;
  }
}
.sideNaviToggle__text {
  float: left;
  display: none;
}
.sideNaviToggle__text.sideNaviToggle__text--open {
  display: block;
}
.sideNaviToggle.cb-toggle-active .sideNaviToggle__text {
  display: block;
}
.sideNaviToggle.cb-toggle-active .sideNaviToggle__text.sideNaviToggle__text--open {
  display: none;
}
.sideNaviOverlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1499;
  background-color: rgba(96, 105, 110, 0.8);
  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0s 0.6s, opacity 0.6s;
  display: none;
}
.wrapper.cb-toggle-target-active .sideNaviOverlay {
  transition: all 0s, opacity 0.6s;
  opacity: 1;
  width: 100%;
  height: 100%;
}
@media (max-width: 1199px) {
  .sideNaviOverlay {
    display: block;
  }
}
.navigationSide div.navi {
  float: left;
}
.navigationSide div.navi .cb-toggle {
  display: none;
}
.navigationSide div.navi > .item {
  float: left;
}
.navigationSide div.navi > .item > .menu {
  display: block;
}
.navigationSide div.sub2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.navigationSide div.sub2 > .item {
  display: none;
  width: 100%;
}
.navigationSide div.sub2 > .item.path {
  display: block;
}
.navigationSide div.sub2 > .item > .menu {
  display: none;
}
@media (max-width: 1199px) {
  .navigationSide div.sub2 {
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    box-sizing: border-box;
    padding: var(--deskSpace);
    background-color: #E6EBEE;
    border-radius: 0 0 0 5px;
  }
  .navigationSide div.sub2::-webkit-scrollbar {
    display: none;
  }
}
.navigationSide div.sub3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.navigationSide div.sub3 > .item {
  width: 100%;
  border-top: 1px solid #E6EBEE;
}
.navigationSide div.sub3 > .item.exit {
  border-bottom: 1px solid #E6EBEE;
}
.navigationSide div.sub3 > .item:not(.item-empty) {
  position: relative;
}
.navigationSide div.sub3 > .item:not(.item-empty) > .cb-toggle {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 46px;
  cursor: pointer;
}
.navigationSide div.sub3 > .item:not(.item-empty) > .cb-toggle:before,
.navigationSide div.sub3 > .item:not(.item-empty) > .cb-toggle:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 16px;
  height: 16px;
  border-radius: 10000px;
  transition: all 0.24s;
}
.navigationSide div.sub3 > .item:not(.item-empty) > .cb-toggle:before {
  background-color: #E6F3EB;
}
.navigationSide div.sub3 > .item:not(.item-empty) > .cb-toggle:after {
  background-color: #007D34;
  mask-size: 8px auto;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/chevron-bottom.svg);
  transition: all 0.24s;
}
.navigationSide div.sub3 > .item:not(.item-empty) > .cb-toggle:hover:before {
  background-color: #B0DAC2;
}
.navigationSide div.sub3 > .item:not(.item-empty) > .cb-toggle.cb-toggle-active:after {
  transform: translateY(-50%) rotate(-180deg);
}
.navigationSide div.sub3 > .item > .menu {
  text-decoration: none;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  color: #191F24;
  padding: 10px 0 10px 30px;
}
@media (max-width: 767px) {
  .navigationSide div.sub3 > .item > .menu {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
@media (max-width: 1199px) {
  .navigationSide div.sub3 > .item {
    border-color: #fff;
  }
  .navigationSide div.sub3 > .item:not(.item-empty) > .cb-toggle:before {
    background-color: #fff;
  }
  .navigationSide div.sub3 > .item.exit {
    border-color: #fff;
  }
}
.navigationSide div.sub4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding-left: 30px;
  height: 0;
  overflow: hidden;
  transition: all 0.6s;
}
.navigationSide div.sub4 > .item {
  margin-top: 8px;
  transform: translateY(-10px);
  opacity: 0;
  transition: all 0.2s;
}
.navigationSide div.sub4 > .item.init {
  margin-top: 4px;
}
.navigationSide div.sub4 > .item.exit {
  margin-bottom: 40px;
}
.navigationSide div.sub4 > .item > .menu {
  text-decoration: none;
  font-size: 12px;
  line-height: 1.58333333;
  font-weight: 700;
  color: #191F24;
  font-weight: 500;
  transition: all 0.24s;
}
.navigationSide div.sub4 > .item > .menu:hover,
.navigationSide div.sub4 > .item > .menu.path {
  color: #007D34;
}
.navigationSide div.sub3 > .item.cb-toggle-target-active div.sub4 {
  height: var(--js-elementHeight);
}
.navigationSide div.sub3 > .item.cb-toggle-target-active div.sub4 > .item {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s 0.2s;
}
.togglenavigation {
  float: left;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  padding-right: 30px;
  text-decoration: none;
  color: #191F24 !important;
  margin-left: 24px;
  display: none;
}
@media (max-width: 1199px) {
  .togglenavigation {
    display: block;
  }
}
@media (max-width: 767px) {
  .togglenavigation {
    margin-left: 14px;
  }
}
.tline {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 2px;
  background-color: #191F24;
  transition: all 0.4s;
}
.tline--2,
.tline--3 {
  width: 15px;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.navigationMobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background-color: #E6EBEE;
  transform: translateY(-100%);
  transition: all 0.6s;
  display: none;
}
.navigationMobile::-webkit-scrollbar {
  display: none;
}
body.cb-toggle-target-active .navigationMobile {
  transform: translateY(0);
}
@media (max-width: 1199px) {
  .navigationMobile {
    display: block;
  }
}
.navigationMobile__wrapper {
  float: left;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding-top: calc(var(--headerHeight) + var(--altVerticalSpace200));
  padding-bottom: var(--altVerticalSpace200);
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767px) {
  .navigationMobile__wrapper {
    padding-top: calc(var(--headerHeight) + 56px);
    padding-bottom: 56px;
  }
}
.navigationMobile__content {
  float: left;
  width: 450px;
  max-width: var(--deskMaxWidth);
}
.productRangeAnchor {
  order: 2;
  float: left;
  margin-top: -24px;
  color: #007D34 !important;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #007D34, #007D34);
  transition: all 0.24s;
  opacity: 0;
  transition: all 0.1s;
}
@media (max-width: 767px) {
  .productRangeAnchor {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.productRangeAnchor.path {
  background-size: 100% 2px;
}
@media (min-width: 768px) {
  .productRangeAnchor:hover {
    background-size: 100% 2px;
  }
}
.navigationMobile div.sub2 > .item.cb-toggle-target-active .productRangeAnchor {
  transition: all 0.4s, opacity 0.4s 0.2s;
  opacity: 1;
  margin-top: var(--altVerticalSpace40);
}
.navigationMobile div.navi {
  float: left;
}
.navigationMobile div.navi .cb-toggle {
  display: none;
}
.navigationMobile div.navi > .item {
  float: left;
}
.navigationMobile div.navi > .item > .menu {
  display: block;
  text-decoration: none;
  color: #191F24;
}
.navigationMobile div.sub2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.navigationMobile div.sub2 > .item {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  margin-top: 40px;
}
.navigationMobile div.sub2 > .item.init {
  margin-top: 0 !important;
}
.navigationMobile div.sub2 > .item:not(.item-empty) {
  width: calc(100% + 34px);
  margin-left: -34px;
  padding-left: 34px;
}
.navigationMobile div.sub2 > .item:not(.item-empty) > .cb-toggle {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 34px;
  cursor: pointer;
}
.navigationMobile div.sub2 > .item:not(.item-empty) > .cb-toggle:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 100%;
  background-color: #191F24;
  mask-size: 12px auto;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/chevron-bottom.svg);
  transition: all 0.4s;
}
.navigationMobile div.sub2 > .item:not(.item-empty) > .cb-toggle.cb-toggle-active:after {
  transform: rotate(-180deg);
}
.navigationMobile div.sub2 > .item > .menu {
  float: left;
  font-size: 26px;
  line-height: 1.30769231;
  font-weight: 700;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #007D34, #007D34);
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .navigationMobile div.sub2 > .item > .menu {
    font-size: 20px;
    line-height: 1.5;
  }
}
.navigationMobile div.sub2 > .item > .menu.path {
  background-size: 100% 2px;
}
@media (min-width: 768px) {
  .navigationMobile div.sub2 > .item > .menu:hover {
    background-size: 100% 2px;
  }
}
@media (max-width: 767px) {
  .navigationMobile div.sub2 > .item {
    padding-left: 32px !important;
    margin-top: 24px;
  }
  .navigationMobile div.sub2 > .item:not(.item-empty) {
    width: 100%;
    margin-left: 0;
  }
  .navigationMobile div.sub2 > .item:not(.item-empty) > .cb-toggle {
    height: 30px;
  }
}
.navigationMobile div.sub3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 0;
  overflow: hidden;
  transition: all 0.6s;
}
.navigationMobile div.sub3 > .item {
  width: 100%;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s;
}
.navigationMobile div.sub3 > .item.init {
  margin-top: 32px !important;
}
.navigationMobile div.sub3 > .item.exit {
  margin-bottom: 16px;
}
.navigationMobile div.sub3 > .item > .menu {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
}
@media (max-width: 767px) {
  .navigationMobile div.sub3 > .item > .menu {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.navigationMobile div.sub3 > .item > .menu.path {
  color: #007D34;
}
.navigationMobile div.sub2 > .item--smallNavi div.sub3 > .item {
  margin-top: 16px;
}
.navigationMobile div.sub2 > .item--bigNavi {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.navigationMobile div.sub2 > .item--bigNavi > .menu {
  order: 1;
}
.navigationMobile div.sub2 > .item--bigNavi div.sub3 {
  order: 3;
}
.navigationMobile div.sub2 > .item--bigNavi div.sub3 > .item {
  border-top: 1px solid #fff;
}
.navigationMobile div.sub2 > .item--bigNavi div.sub3 > .item.exit {
  border-bottom: 1px solid #fff;
}
.navigationMobile div.sub2 > .item--bigNavi div.sub3 > .item > .menu {
  padding: 11px 0;
}
.navigationMobile div.sub2 > .item.cb-toggle-target-active div.sub3 {
  height: var(--js-elementHeight);
}
.navigationMobile div.sub2 > .item.cb-toggle-target-active div.sub3 > .item {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s 0.2s;
}
.navigationMobile div.sub4 {
  display: none !important;
}
.pictBlock {
  float: left;
  width: 100%;
  display: flex;
  position: relative;
}
@media (max-width: 1199px) {
  .pictBlock {
    flex-direction: column;
    align-items: center;
  }
}
.pictBlock__subcontent {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: var(--moodHeight);
}
.pictBlock__subcontent--1 {
  width: 686px;
  flex-shrink: 0;
  z-index: 2;
  box-sizing: border-box;
  padding-right: 24px;
}
.pictBlock__subcontent--1:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200%;
  height: 500vh;
  background-color: #E6EBEE;
  transform-origin: 100% 100%;
  transform: skew(var(--generalAngle));
}
.layout6 .section--multimood .pictBlock__subcontent--1,
.layout8 .section--multimood .pictBlock__subcontent--1 {
  width: 416px;
  justify-content: flex-start;
}
@media (max-width: 1199px) {
  .pictBlock__subcontent--1 {
    min-height: 0;
    padding: 0;
    width: 100% !important;
  }
  .pictBlock__subcontent--1:before {
    right: initial;
    left: 50%;
    width: calc(100vw + 2px);
    transform: translateX(-50%) skewY(var(--generalAngle));
  }
  .layout6 .section--multimood .pictBlock__subcontent--1,
  .layout8 .section--multimood .pictBlock__subcontent--1 {
    margin-right: 0;
  }
  .layout6 .section--multimood .pictBlock__subcontent--1:before,
  .layout8 .section--multimood .pictBlock__subcontent--1:before {
    top: initial;
    bottom: 0;
    height: 100vh;
  }
}
.pictBlock__subcontent--2 {
  z-index: 1;
  width: calc(100% - (662px + 24px));
}
.layout6 .section--multimood .pictBlock__subcontent--2,
.layout8 .section--multimood .pictBlock__subcontent--2 {
  width: calc(100% - (492px + 24px - 100px));
}
@media (max-width: 1199px) {
  .pictBlock__subcontent--2 {
    width: 100vw !important;
    aspect-ratio: 1.68333333;
    min-height: 0;
  }
}
@media (max-width: 767px) {
  .pictBlock__subcontent--2 {
    aspect-ratio: 1.37931034;
  }
}
.pictBlock__content {
  float: left;
  width: 100%;
  position: relative;
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
}
.layout6 .section--multimood .pictBlock__content,
.layout8 .section--multimood .pictBlock__content {
  width: calc(100% + 100px);
  margin-bottom: calc((var(--spaceTotal) * 2) + 200px);
}
@media (max-width: 1199px) {
  .pictBlock__content {
    margin-bottom: 0;
  }
  .layout6 .section--multimood .pictBlock__content,
  .layout8 .section--multimood .pictBlock__content {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .layout6 .section--multimood .pictBlock__content,
  .layout8 .section--multimood .pictBlock__content {
    margin-bottom: 70px;
  }
}
.part--pictBlockImage {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + ((100vw - var(--deskWidth)) / 2));
  height: 100%;
}
.part--pictBlockImage * {
  height: 100%;
}
.part--pictBlockImage img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.part--pictBlockImage .placeholder {
  padding-bottom: 0;
}
.part--pictBlockImage .cb-image-container {
  border-radius: 0 !important;
}
@media (max-width: 1216px) {
  .part--pictBlockImage {
    width: calc(100% + var(--deskSpace));
  }
}
.cbdModule--pictBlockOverline {
  float: left;
  width: 100%;
  margin-bottom: calc(var(--spacePart) * 2);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  color: #005997;
}
@media (max-width: 767px) {
  .cbdModule--pictBlockOverline {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.cbdModule--pictBlockLink {
  float: left;
  width: 100%;
  margin-top: var(--altVerticalSpace56);
}
.cbdModule--pictBlockLink .open {
  float: left;
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .cbdModule--pictBlockLink .open {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.cbdModule--pictBlockLink .open:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.cbdModule--pictBlockLink .open:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .cbdModule--pictBlockLink .open:hover {
    color: #007D34 !important;
  }
  .cbdModule--pictBlockLink .open:hover:before {
    width: 0;
  }
  .cbdModule--pictBlockLink .open:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .cbdModule--pictBlockLink .open {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.footcontent {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: var(--altVerticalSpace40);
  background-size: 204px auto;
  background-position: right bottom 92px;
  background-repeat: no-repeat;
  background-image: url(/images/papillon.png);
}
@media (max-width: 767px) {
  .footcontent {
    background-size: 154px auto;
    background-position: right bottom 165px;
  }
}
.footsection {
  float: left;
  width: 100%;
}
.footsection--1 {
  display: flex;
  flex-wrap: wrap;
}
.footsection--2 {
  margin-top: var(--altVerticalSpace70);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .footsection--2 {
    flex-direction: column;
    align-items: flex-start;
  }
}
.footunit {
  float: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1199px) {
  .footunit {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .footunit {
    width: 100%;
  }
}
.footunit--2 {
  margin-left: auto;
  margin-right: 170px;
}
@media (max-width: 1199px) {
  .footunit--2 {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .footunit--2 {
    margin-top: 80px;
  }
}
@media (max-width: 1199px) {
  .footunit--3 {
    width: 100%;
    margin-top: 80px;
  }
}
.foottitle {
  float: left;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  color: #005997;
  margin-bottom: calc(var(--spacePart) * 2);
}
@media (max-width: 767px) {
  .foottitle {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.vcard {
  float: left;
}
.footButtons {
  float: left;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.footButton {
  float: left;
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
  padding-left: 24px;
  color: #007D34 !important;
  margin-top: calc(var(--spacePart) * 2);
}
@media (max-width: 767px) {
  .footButton {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.footButton:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.footButton:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .footButton:hover {
    color: #007D34 !important;
  }
  .footButton:hover:before {
    width: 0;
  }
  .footButton:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .footButton {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.footButton:before {
  right: initial;
  left: -10px;
  width: 0;
  background-color: var(--buttonSecondaryOverlay);
}
.footButton:after {
  display: none;
}
@media (min-width: 768px) {
  .footButton:hover:before {
    width: calc(100% + 20px);
  }
}
.footButton:first-child {
  margin-top: 0;
}
.footStamps {
  float: left;
  margin-top: var(--altVerticalSpace70);
  display: flex;
  align-items: flex-start;
}
.footStamp {
  float: left;
  width: 104px;
  margin-right: 40px;
}
.footStamp:last-child {
  margin-right: 0;
}
.unit--footLinks {
  float: left;
}
#edit .unit--footLinks {
  outline-color: #ccc;
  min-height: 100px;
}
.unit--footLinks .unit__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.unit--footLinks .part {
  margin-top: 8px;
  margin-bottom: 0;
}
.unit--footLinks .part:first-child {
  margin-top: 0;
}
.unit--footLinks .part--footlink .open {
  float: left;
  color: #007D34 !important;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #007D34, #007D34);
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .unit--footLinks .part--footlink .open {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.unit--footLinks .part--footlink .open.path {
  background-size: 100% 2px;
}
@media (min-width: 768px) {
  .unit--footLinks .part--footlink .open:hover {
    background-size: 100% 2px;
  }
}
#social {
  float: left;
}
#social .meta {
  float: left;
  width: 32px;
  height: 32px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-color: #007D34;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  margin-right: 14px;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta:hover,
#social .meta:focus {
  background-color: #006128;
}
#social .meta.service_linkedin {
  mask-image: url(/images/social-linkedin.svg);
}
#social .meta.service_facebook {
  mask-image: url(/images/social-facebook.svg);
}
#social .meta.service_instagram {
  mask-image: url(/images/social-instagram.svg);
}
#social .meta.service_youtube {
  mask-image: url(/images/social-youtube.svg);
}
#legal {
  float: left;
}
#legal .meta {
  float: left;
  margin-right: 40px;
  font-size: 12px;
  line-height: 1.58333333;
  font-weight: 700;
  text-decoration: none;
  color: #191F24;
  font-weight: 500;
  transition: all 0.24s;
}
#legal .meta:last-child {
  margin-right: 0;
}
#legal .meta:hover {
  color: #007D34;
}
@media (max-width: 767px) {
  #legal {
    order: 2;
  }
}
.langnavi {
  float: left;
}
@media (max-width: 767px) {
  .langnavi {
    order: 1;
    margin-bottom: 16px;
  }
}
.langnavi div.sub1 {
  float: left;
  display: block;
}
.langnavi div.sub1 > .item {
  float: left;
  margin-right: 16px;
}
.langnavi div.sub1 > .item.exit {
  margin-right: 0;
}
.langnavi div.sub1 > .item > .menu {
  float: left;
  font-size: 12px;
  line-height: 1.58333333;
  font-weight: 700;
  text-decoration: none;
  color: #191F24;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.24s;
}
.langnavi div.sub1 > .item > .menu:hover,
.langnavi div.sub1 > .item > .menu.path {
  color: #007D34;
}
.ns-productTeaserWrapper {
  float: left;
  width: 100%;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.ns-productTeaserWrapper:has(.ns-productTeaserArea:empty) {
  display: none;
}
.ns-productTeaserContent {
  float: left;
  width: 100%;
}
.ns-productTeaserArea {
  float: left;
  width: calc(100% + 24px);
  margin: -12px;
  display: flex;
  flex-wrap: wrap;
}
.ns-productTeaser {
  float: left;
  width: calc(50% - 24px);
  margin: 12px;
  background-color: #E6EBEE;
  border-radius: 5px;
  overflow: hidden;
  color: #191F24 !important;
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .ns-productTeaser:hover {
    background-color: #DAE1E6;
  }
}
@media (max-width: 767px) {
  .ns-productTeaser {
    width: calc(100% - 24px);
  }
}
.ns-productSlider .ns-productTeaser,
.ns-unitSlider .ns-productTeaser {
  width: calc(100% - 24px) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  flex-grow: 1;
}
.ns-productTeaser__content {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: calc(40px - var(--spacePart)) var(--generalUnitPadding);
}
@media (max-width: 767px) {
  .ns-productTeaser__content {
    padding-top: calc(30px - var(--spacePart));
    padding-bottom: calc(30px - var(--spacePart));
  }
}
.ns-productTeaser__part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.ns-productTeaser__part--image {
  height: 176px;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
}
.ns-productTeaser__part--image * {
  height: 100%;
}
.ns-productTeaser__part--image img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.ns-productTeaser__part--image .placeholder {
  padding-bottom: 0;
}
.ns-productTeaser__part--image .ns-productTeaserImage,
.ns-productTeaser__part--image .ns-sliderImage {
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .ns-productTeaser:hover .ns-productTeaser__part--image .ns-productTeaserImage,
  .ns-productTeaser:hover .ns-productTeaser__part--image .ns-sliderImage {
    transform: scale(1.05);
  }
}
.ns-productTeaser__part--category .ns-productTeaserCategory {
  display: inline;
  background-color: #005997;
  border-radius: 10000px;
  color: #fff;
  box-decoration-break: clone;
  padding: 5px 12px;
  font-size: 12px;
  line-height: 2.33333333;
  font-weight: 700;
}
.ns-productTeaser__part--category .ns-productTeaserCategory a:hover {
  color: #E6EBEE;
}
.area--two .unitThree .ns-productTeaser__part--category .ns-productTeaserCategory {
  font-size: 16px;
  line-height: 1.875;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ns-productTeaser__part--description {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 400;
}
@media (max-width: 767px) {
  .ns-productTeaser__part--description {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.cbdModule--suggestProductTeaserSlider {
  float: left;
  width: 100%;
  margin-top: calc(var(--altVerticalSpace200) - var(--spaceTotal));
  margin-bottom: var(--spaceTotal);
  position: relative;
}
#edit .cbdModule--suggestProductTeaserSlider {
  min-height: 50px;
}
#edit .cbdModule--suggestProductTeaserSlider:has(.ns-productSlider__area:empty) {
  padding-top: 34px;
}
#edit .cbdModule--suggestProductTeaserSlider:has(.ns-productSlider__area:empty):before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1.33333333;
  font-weight: 700;
  min-height: 24px;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 1px;
  background-color: #71CAF3;
  color: #fff;
  padding: 4px 10px;
}
#edit .cbdModule--suggestProductTeaserSlider:has(.ns-productSlider__area:empty):before {
  content: 'Keine Produkt-Teaser ausgewählt';
}
#edit .cbdModule--suggestProductTeaserSlider:not(.is-marked) {
  outline: 2px dashed #ccc;
  outline-offset: 3px;
}
#edit .cbdModule--suggestProductTeaserSlider .ns-productTeaser {
  pointer-events: none;
}
.ns-productSliderWrapper {
  float: left;
  width: 100%;
}
.ns-productSlider {
  float: left;
  width: 100%;
}
.ns-productSlider__content {
  float: left;
  width: 100%;
}
.ns-slider__stage {
  float: left;
  width: 100%;
}
.ns-productSlider__area {
  float: left;
  width: calc(100% + 24px);
  margin-left: -12px;
  display: flex;
}
.ns-productSlide {
  width: 33.33333333%;
  margin: unset;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.6s;
  opacity: 0;
  transform: scale(0.8);
}
.ns-productSlide.is-active {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1199px) {
  .ns-productSlide {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .ns-productSlide {
    width: 100%;
  }
}
.ns-sliderControls {
  float: left;
  width: 100%;
  margin-top: var(--altVerticalSpace56);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ns-sliderWrapper.is-noSlider .ns-sliderControls {
  display: none !important;
}
@media (max-width: 767px) {
  .ns-sliderControls {
    margin-top: 16px;
  }
}
.ns-sliderIndex {
  float: left;
  width: 100%;
}
.ns-sliderIndex__content {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .ns-sliderIndex__content {
    justify-content: flex-start;
    width: calc(100% + 24px);
    margin-left: -12px;
  }
}
.ns-sliderIndex__item {
  float: left;
  width: 32px;
  height: 32px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  position: relative;
  cursor: pointer;
}
.ns-sliderIndex__item:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 10000px;
  background: #E6F3EB;
  transition: all 0.24s;
}
.ns-sliderIndex__item.is-active:after {
  background-color: #007D34;
  transform: translate(-50%, -50%) scale(1.2);
}
.ns-sliderControl {
  float: left;
  flex-shrink: 0;
  width: 42px;
  height: 42px;
  border-radius: 10000px;
  background-color: #E6F3EB;
  position: relative;
  transition: all 0.24s;
  cursor: pointer;
}
.ns-sliderControl:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #007D34;
  mask-size: 16px auto;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
}
.ns-sliderControl:hover {
  background-color: #B0DAC2;
}
.ns-sliderControl--prev {
  margin-left: 33px;
}
.ns-sliderControl--prev:after {
  mask-image: url(/images/arrow-left.svg);
}
.ns-sliderControl--next {
  margin-left: 16px;
}
.ns-sliderControl--next:after {
  mask-image: url(/images/arrow-right.svg);
}
.ns-referenceTeaserWrapper {
  float: left;
  width: 100%;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.ns-referenceTeaserWrapper:has(.ns-referenceTeaserArea:empty) {
  display: none;
}
.ns-referenceTeaserContent {
  float: left;
  width: 100%;
}
.ns-referenceTeaserArea {
  float: left;
  width: calc(100% + 24px);
  margin: -12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ns-referenceTeaser {
  float: left;
  width: calc(100% - 24px);
  margin: 12px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
@media (min-width: 1200px) {
  .ns-referenceTeaser:nth-child(4n-3),
  .ns-referenceTeaser:nth-child(4n) {
    width: calc((100%/1368*570) - 24px);
  }
  .ns-referenceTeaser:nth-child(4n-2),
  .ns-referenceTeaser:nth-child(4n-1) {
    width: calc((100%/1368*798) - 24px);
  }
}
.ns-referenceSlider .ns-referenceTeaser {
  width: calc(100% - 24px) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  flex-grow: 1;
}
.ns-referenceTeaser__content {
  float: left;
  width: 100%;
  position: relative;
  z-index: 2;
  flex-grow: 1;
  box-sizing: border-box;
  padding: calc(var(--generalUnitPadding) - var(--spacePart)) var(--generalUnitPadding);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: var(--galleryImageHeight);
  color: #fff;
}
.ns-referenceTeaser__part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.ns-referenceTeaser__part--image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
.ns-referenceTeaser__part--image * {
  height: 100%;
}
.ns-referenceTeaser__part--image img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.ns-referenceTeaser__part--image .placeholder {
  padding-bottom: 0;
}
.ns-referenceTeaser__part--image:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 60%;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  opacity: 0.8;
}
.ns-referenceTeaser__part--image .ns-referenceTeaserImage {
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .ns-referenceTeaser:hover .ns-referenceTeaser__part--image .ns-referenceTeaserImage {
    transform: scale(1.05);
  }
}
.ns-referenceTeaser__part--tag .ns-referenceTeaserTag {
  display: inline;
  background-color: #005997;
  border-radius: 10000px;
  color: #fff;
  box-decoration-break: clone;
  padding: 5px 12px;
  font-size: 12px;
  line-height: 2.33333333;
  font-weight: 700;
}
.ns-referenceTeaser__part--tag .ns-referenceTeaserTag a:hover {
  color: #E6EBEE;
}
.area--two .unitThree .ns-referenceTeaser__part--tag .ns-referenceTeaserTag {
  font-size: 16px;
  line-height: 1.875;
  padding-top: 3px;
  padding-bottom: 3px;
}
.cbdModule--referenceTeaserSlider {
  float: left;
  width: 100%;
  margin-top: calc(var(--altVerticalSpace200) - var(--spaceTotal));
  margin-bottom: var(--spaceTotal);
  position: relative;
}
#edit .cbdModule--referenceTeaserSlider {
  min-height: 50px;
}
#edit .cbdModule--referenceTeaserSlider:has(.ns-referenceSlider__area:empty) {
  padding-top: 34px;
}
#edit .cbdModule--referenceTeaserSlider:has(.ns-referenceSlider__area:empty):before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1.33333333;
  font-weight: 700;
  min-height: 24px;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 1px;
  background-color: #71CAF3;
  color: #fff;
  padding: 4px 10px;
}
#edit .cbdModule--referenceTeaserSlider:has(.ns-referenceSlider__area:empty):before {
  content: 'Keine Produkt-Teaser ausgewählt';
}
#edit .cbdModule--referenceTeaserSlider:not(.is-marked) {
  outline: 2px dashed #ccc;
  outline-offset: 3px;
}
#edit .cbdModule--referenceTeaserSlider .ns-productTeaser {
  pointer-events: none;
}
.ns-referenceSliderWrapper {
  float: left;
  width: 100%;
}
.ns-referenceSlider {
  float: left;
  width: 100%;
}
.ns-referenceSlider__content {
  float: left;
  width: 100%;
}
.ns-referenceSlider__area {
  float: left;
  width: calc(100% + 24px);
  margin-left: -12px;
  display: flex;
}
.ns-referenceSlide {
  width: 50%;
  margin: unset;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.6s;
  opacity: 0;
  transform: scale(0.8);
}
.ns-referenceSlide.is-active {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1199px) {
  .ns-referenceSlide {
    width: 100%;
  }
}
.cbdModule--unitSlider {
  margin-top: calc(var(--altVerticalSpace200) - var(--spaceTotal));
  margin-bottom: calc(var(--altVerticalSpace200) - var(--spaceTotal));
}
.ns-unitSliderWrapper {
  float: left;
  width: 100%;
}
.ns-unitSlider {
  float: left;
  width: 100%;
}
.ns-unitSlider__section {
  float: left;
  width: 100%;
}
.ns-unitSlider__section--controls {
  display: flex;
  align-items: flex-end;
}
@media (max-width: 767px) {
  .ns-unitSlider__section--controls {
    flex-direction: column;
    align-items: flex-start;
  }
}
.ns-unitSlider__content {
  float: left;
  width: 100%;
}
.ns-slider__stage {
  float: left;
  width: 100%;
}
.ns-unitSlider__area {
  float: left;
  width: calc(100% + 24px);
  margin-left: -12px;
  display: flex;
}
.ns-slider__part {
  float: left;
  width: 100%;
}
.ns-slider__part--sliderTitle {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  color: #005997;
  margin-bottom: calc(var(--spacePart) * 2);
}
@media (max-width: 767px) {
  .ns-slider__part--sliderTitle {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.ns-slider__part--sliderLink {
  width: auto;
  flex-shrink: 0;
  margin-right: 32px;
  margin-top: var(--altVerticalSpace56);
}
.ns-slider__part--sliderLink .open {
  float: left;
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .ns-slider__part--sliderLink .open {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.ns-slider__part--sliderLink .open:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.ns-slider__part--sliderLink .open:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .ns-slider__part--sliderLink .open:hover {
    color: #007D34 !important;
  }
  .ns-slider__part--sliderLink .open:hover:before {
    width: 0;
  }
  .ns-slider__part--sliderLink .open:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .ns-slider__part--sliderLink .open {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
@media (max-width: 767px) {
  .ns-slider__part--sliderLink {
    order: 2;
  }
}
/*# sourceMappingURL=./screen-small.css.map */